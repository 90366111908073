import {ReactElement} from 'react';
import {ListboxItemProps} from '@common/ui/forms/listbox/item';
import {useNavigate} from '@common/utils/hooks/use-navigate';
import {Menu, MenuItem, MenuTrigger} from '@common/ui/navigation/menu/menu-trigger';
import {Trans} from '@common/i18n/trans';
import {LocalMoviesIcon} from '@common/icons/material/LocalMovies';
import {TvIcon} from '@common/icons/material/Tv';
import {AnimationIcon} from '@common/icons/material/Animation';
import {VideocamIcon} from '@common/icons/material/Videocam';
import {GamepadIcon} from '@common/icons/material/Gamepad';
import {AppsIcon} from '@common/icons/material/Apps';
import {BookIcon, ModelTrainingIcon, MusicVideoIcon, VideogameAssetIcon} from '@common/icons/material/all-icons';
import Cookies from 'js-cookie';

interface Props {
  children: ReactElement;
  items?: ReactElement<ListboxItemProps>[];
}

export function NavbarDropBoxMenu({children}: Props) {
  const navigate = useNavigate();
  const streamingOnly = Cookies.get('streaming_only') === '1';

    return  (<MenuTrigger>
        {children}
        <Menu>
          <MenuItem
            key={0}
            value="movies"
            startIcon={<LocalMoviesIcon />}
            onSelected={() => {
              navigate('/movies');
            }}
          >
            <Trans message="Movies" />
          </MenuItem>
          <MenuItem
            key={1}
            value="series"
            startIcon={<TvIcon />}
            onSelected={() => {
              navigate('/series');
            }}
          >
            <Trans message="Series" />
          </MenuItem>
          <MenuItem
            key={2}
            value="animes"
            startIcon={<AnimationIcon />}
            onSelected={() => {
              navigate('/animes');
            }}
          >
            <Trans message="Animes" />
          </MenuItem>
          <MenuItem
            key={3}
            value="docus"
            startIcon={<VideocamIcon />}
            onSelected={() => {
              navigate('/docs');
            }}
          >
            <Trans message="Docs" />
          </MenuItem>
          <MenuItem
            key={4}
            value="games"
            startIcon={<GamepadIcon />}
            onSelected={() => {
              navigate('/jeux');
            }}
          >
            <Trans message="Games" />
          </MenuItem><MenuItem
          key={5}
          value="apps"
          startIcon={<AppsIcon />}
          onSelected={() => {
            navigate('/app');
          }}
        >
          <Trans message="Apps" />
        </MenuItem><MenuItem
          key={6}
          value="music"
          startIcon={<MusicVideoIcon />}
          onSelected={() => {
            navigate('/music');
          }}
        >
          <Trans message="Music" />
        </MenuItem><MenuItem
          key={7}
          value="ebooks"
          startIcon={<BookIcon />}
          onSelected={() => {
            navigate('/ebooks');
          }}
        >
          <Trans message="Ebooks" />
        </MenuItem><MenuItem
          key={8}
          value="trainings"
          startIcon={<ModelTrainingIcon />}
          onSelected={() => {
            navigate('/formations');
          }}
        >
          <Trans message="Trainings" />
        </MenuItem><MenuItem
          key={9}
          value="emulations"
          startIcon={<VideogameAssetIcon />}
          onSelected={() => {
            navigate('/emulations');
          }}
        >
          <Trans message="Emulations" />
        </MenuItem>
        </Menu>
      </MenuTrigger>)

}

