import React from "react";
import { createSvgIcon } from "../create-svg-icon";

export const BatteryCharging60Icon = createSvgIcon(
  <React.Fragment>
    <path
      fillOpacity=".3"
      d="M15.67 4H14V2h-4v2H8.33C7.6 4 7 4.6 7 5.33V11h3.87L13 7v4h4V5.33C17 4.6 16.4 4 15.67 4z"
    />
    <path d="M13 12.5h2L11 20v-5.5H9l1.87-3.5H7v9.67C7 21.4 7.6 22 8.33 22h7.33c.74 0 1.34-.6 1.34-1.33V11h-4v1.5z" />
  </React.Fragment>,
  "BatteryCharging60Outlined"
);
