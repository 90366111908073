import React, {createContext, useContext, useState, ReactNode} from 'react';

// Définir le type pour le contexte du menu
interface MenuContextType {
  isMenuVisible: boolean;
  setIsMenuVisible: (isVisible: boolean) => void;
}

// Créer le contexte avec une valeur par défaut
const MenuContext = createContext<MenuContextType | undefined>(undefined);

export const useMenu = () => {
  const context = useContext(MenuContext);
  if (!context) {
    throw new Error('useMenu must be used within a MenuProvider');
  }
  return context;
};

// Définir le type des props de MenuProvider
interface MenuProviderProps {
  children: ReactNode;
}

export const MenuProvider: React.FC<MenuProviderProps> = ({children}) => {
  const [isMenuVisible, setIsMenuVisible] = useState(true);

  return (
    <MenuContext.Provider value={{isMenuVisible, setIsMenuVisible}}>
      {children}
    </MenuContext.Provider>
  );
};
