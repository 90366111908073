// find the highest percentage decrease between monthly and yearly prices of specified products
import {Product} from '../product';
import {findBestPrice, UpsellBillingCycle} from './find-best-price';
import {Fragment, memo} from 'react';
import {Trans} from '../../i18n/trans';

interface UpsellLabelProps {
  products: Product[];
  token: UpsellBillingCycle;
}
export const UpsellLabel = memo(function UpsellLabel({
  products,
  token,
}: UpsellLabelProps) {
  const upsellPercentage = calcHighestUpsellPercentage(products,token);
console.log(token);
  if (upsellPercentage <= 0) {
    return null;
  }

  return (
    <Fragment>
      <span className="font-medium text-positive-darker">
        {' '}
        (
        <Trans
          message="Save up to :percentage%"
          values={{percentage: upsellPercentage}}
        />
        )
      </span>
    </Fragment>
  );
});

function calcHighestUpsellPercentage(products: Product[] , token: UpsellBillingCycle) {
  if (!products.length) return 0;

  const decreases = products.map(product => {
    if (product.hidden) return 0;

    const monthly = findBestPrice('monthly', product.prices);
    const yearly = findBestPrice('yearly', product.prices);
    const twoyears = findBestPrice('2years', product.prices);
    const threeyears = findBestPrice('3years', product.prices);

    if (!monthly || !yearly || !twoyears || !threeyears) return 0;

    // monthly plan per year amount
    const monthlyAmount = monthly.amount * (12 * (token === '2years' ? 2 : token === '3years' ? 3 : 1))

    if(token === 'yearly') {
      const yearlyAmount = yearly.amount;
      const savingsPercentage = Math.round(
        ((monthlyAmount - yearlyAmount) / monthlyAmount) * 100
      );
      if (savingsPercentage > 0 && savingsPercentage <= 200) {
        return savingsPercentage;
      }
    }
    if(token === '2years') {
      const twoYearsAmount = twoyears.amount;
      const savingsPercentage2 = Math.round(
        ((monthlyAmount - twoYearsAmount) / monthlyAmount) * 100
      );
      if (savingsPercentage2 > 0) {
        return savingsPercentage2;
      }
    }
    if(token === '3years') {
      const threeYearsAmount = threeyears.amount;
      const savingsPercentage3 = Math.round(

        ((monthlyAmount - threeYearsAmount) / monthlyAmount) * 100
      );
      if (savingsPercentage3 > 0) {
        return savingsPercentage3;
      }
    }
    return 0;

  });
  console.log(decreases);
  return Math.max(Math.max(...decreases), 0);
}
