import {IconButton} from '@common/ui/buttons/icon-button';
import {PersonIcon} from '@common/icons/material/Person';
import {ButtonBase} from '@common/ui/buttons/button-base';
import {ArrowDropDownIcon} from '@common/icons/material/ArrowDropDown';
import React, {ReactElement} from 'react';
import {ListboxItemProps} from '@common/ui/forms/listbox/item';
import {NavbarDropBoxMenu} from '@common/ui/navigation/navbar/navbar-dropbox-menu';
import {Trans} from '@common/i18n/trans';
import {CategoryIcon} from '@common/icons/material/Category';


export interface NavbarDropboxProps {
  items?: ReactElement<ListboxItemProps>[];
  className?: string;
}

export function NavbarDropbox({items = [],  className}: NavbarDropboxProps) {
  const desktopButton = (
    <ButtonBase className="flex items-center max-md:hidden" role="presentation">
      <div className="mr-2 max-w-124 overflow-x-hidden overflow-ellipsis text-sm">
        <span className="block">
          <Trans message="Categories" />
        </span>
      </div>
      <ArrowDropDownIcon className="block icon-sm" />
    </ButtonBase>
  );

  return (
    <NavbarDropBoxMenu items={items}>
      <span role="button">
        {desktopButton}
      </span>
    </NavbarDropBoxMenu>
  );
}


