import React from "react";
import { createSvgIcon } from "../create-svg-icon";

export const SignalCellularConnectedNoInternet2BarIcon = createSvgIcon(
  <React.Fragment>
    <path fillOpacity=".3" d="M22 8V2L2 22h16V8h4z" />
    <path d="M14 22V10L2 22h12zm6-12v8h2v-8h-2zm0 12h2v-2h-2v2z" />
  </React.Fragment>,
  "SignalCellularConnectedNoInternet2BarOutlined"
);
