import React from 'react';
import {createSvgIcon} from '../create-svg-icon';

export const SignalWifi2BarIcon = createSvgIcon(
  <React.Fragment>
    <path
      fillOpacity=".3"
      d="M23.64 7c-.45-.34-4.93-4-11.64-4C5.28 3 .81 6.66.36 7L12 21.5 23.64 7z"
    />
    <path d="M4.79 12.52L12 21.5l7.21-8.99C18.85 12.24 16.1 10 12 10s-6.85 2.24-7.21 2.52z" />
  </React.Fragment>,
  'SignalWifi2BarOutlined'
);
