import React from 'react';
import {createSvgIcon} from '../create-svg-icon';

export const BatteryCharging30Icon = createSvgIcon(
  <React.Fragment>
    <path
      fillOpacity=".3"
      d="M15.67 4H14V2h-4v2H8.33C7.6 4 7 4.6 7 5.33v9.17h2L13 7v5.5h2l-1.07 2H17V5.33C17 4.6 16.4 4 15.67 4z"
    />
    <path d="M11 20v-5.5H7v6.17C7 21.4 7.6 22 8.33 22h7.33c.74 0 1.34-.6 1.34-1.33V14.5h-3.07L11 20z" />
  </React.Fragment>,
  'BatteryCharging30Outlined'
);
